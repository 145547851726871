import { useState } from "react";

const Body = () => {
    let phoneNumber = useState(''), password = useState('');

    async function requestAccountDeletion(phoneNumber, password) {
        // write a fetch request         

        let user = await fetch(`https://api.coffeelessonz.com/v1/auth/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phoneNumber: phoneNumber,
                password: password,
                installationId: `web-deletion-request-${Date.now()}`,
                force: true
            })
        }).then(i => i.json()).catch(e => {
            console.dir({e})
            return false
        })

        if (!user?.data?.token) {
            return false
        }

        let deletion = await fetch(`https://api.coffeelessonz.com/v1/profile`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.data.token}`
            }
        }).then(i => i.json()).catch(d => {
            console.dir({d})
            return false
        })

        console.dir({deletion})

        if (!deletion.error) {
            alert('Account Deletion Requested. We will notify you through an sms when your account is deleted.')
            return true
        } else {
            return false
        }
    }

    return (
        <div className="about-area ptb--120  bg_color--1">
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-7 visibleOverParticles">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">Sad to see you go 😢</h2>
                                    <p className="description">
                                        you can request for your account to be deleted here by providing your email / phone-number along with your password
                                    </p>

                                    <input 
                                        type="text" 
                                        placeholder="phone-number" 
                                        className="form-control" 
                                        value={phoneNumber[0]}
                                        onChange={(e) => phoneNumber[1](e.target.value)}
                                        />
                                    <br/>
                                    <input 
                                        type="password" 
                                        placeholder="password" 
                                        className="form-control"
                                        value={password[0]}
                                        onChange={(e) => password[1](e.target.value)}
                                        />

                                    <div className="row mt--30">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="form-group">
                                                <button className="btn btn-outline-danger btn-block" onClick={
                                                    async () => {
                                                        let result = await requestAccountDeletion(phoneNumber[0], password[0])
                                                        console.dir({ result })
                                                        if (!result) {
                                                            alert("Account Deletion Request Failed. Please check your credentials and try again.")
                                                        }
                                                    }
                                                }>Request Account Deletion</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )   
}

export default Body;