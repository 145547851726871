import arm from '../../../assets/icons/arm-3.svg'
import simple from '../../../assets/icons/simple.svg'
import magnet from '../../../assets/icons/magnet-2.svg'
import ball from '../../../assets/icons/ball.svg'

const style = {width: '70px', height: 'auto', textAlign: 'center'}
var sellingPoints = [
    
    {
        icon: <img src={arm} style={style} alt="not hard" />,
        title: 'Easy',
        description: 'Approachable lessons that are fun to learn for people of all backgrounds.',
    },
    
    {
        icon: <img src={magnet} style={style} alt="magnet" />,
        title: 'Engaging',
        description: 'Content designed using concepts borrowed from Game design, to keep you engaged in achieving your learning goals.',
    },
    {
        icon: <img src={simple} style={style} alt="simple" />,
        title: 'Simple',
        description: 'Simple bite sized lessons everyday that keep you engaged and motivated.',
    },
    {
        icon: <img src={ball} style={style} alt="ball" />,
        title: 'Fun',
        description: 'Plus it\'s just plain fun...',
    },
]


export default sellingPoints