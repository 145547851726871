import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home';
import './App.css';
import Error404 from './pages/Error404';
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AccountDeleteRequest from './pages/AccountDeleteRequest';


function App() {
  return (
    <BrowserRouter basename={'/'} element={<HomePage />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="/ComingSoon" element={<ComingSoon />} />
        <Route path="/PP" element={<PrivacyPolicy />} />
        <Route path="/account/delete" element={<AccountDeleteRequest />} />

        {/* <Route path="*" element={<Navigate to="/404" replace />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
