import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './App';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCBmOb7xiAcmx05vdLHMq4AnPX8swsVH5Q",
  authDomain: "coffeelessonz.firebaseapp.com",
  projectId: "coffeelessonz",
  storageBucket: "coffeelessonz.appspot.com",
  messagingSenderId: "551157567065",
  appId: "1:551157567065:web:fbc21379a0f3a2f15beb48",
  measurementId: "G-3C2VT0CXK7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
