import {GrAppleAppStore} from "react-icons/gr";
import { FaGooglePlay} from "react-icons/fa";
export let mobile_tabs = [
    {
        icon: <FaGooglePlay />,
        title: 'Get Early Access',
        // element: 'or download the apk',
    },
    {
        icon: <GrAppleAppStore />,
        title: 'Download from the ios App Store',
        element: 'Coming Soon...',
        disabled: true,
    },
    
]

export let web_tabs = [
    {
        // icon: <GrAppleAppStore />,
        title: 'Coming soon...',
    },
]