import React from 'react';

import Helmet from "../components/common/Helmet";

import Header from "../components/sections/header/Header";
import Footer from '../components/sections/footer/Footer';

import Body from "../components/sections/account_delete/Body";


const AccountDeleteRequest = () => {
    return (
        <div>
            {/* seo content */}
            <Helmet />
            <div className="active-light">
                <Header />
                <Body />
                <Footer />
            </div>

        </div>
    )
}

export default AccountDeleteRequest;